<ng-select
  [formControl]="formControl"
  [items]="items"
  bindLabel="name"
  bindValue="value"
  [clearable]="props['clearable']"
  [multiple]="props['multiple']"
  [formlyAttributes]="field"
  appendTo="body"
  [attr.aria-label]="props['aria-label']"
>
</ng-select>

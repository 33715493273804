<div class="input-group">
  <button
    (click)="fileInput.click()"
    class="tw-btn tw-btn-outline"
    type="button"
    title="Replace file"
  >
    <i class="bi bi-upload"></i>
  </button>
  <input
    type="text"
    (click)="fileInput.click()"
    class="form-control"
    placeholder="No file uploaded"
    [value]="formControl.value?.key"
    aria-label="file"
    readonly
  />
  @if (state === stateEnum.FileUploaded) {
    <button
      (click)="gotoViewableLink()"
      class="tw-btn tw-btn-outline"
      type="button"
      title="View file"
    >
      <i class="bi bi-eye"></i>
    </button>
    <button
      (click)="removeFile()"
      class="tw-btn tw-btn-danger"
      type="button"
      title="Remove file"
    >
      <i class="bi bi-trash"></i>
    </button>
  }
</div>
@if (state === stateEnum.FileUploading) {
  <div class="progress" [ngStyle]="{ 'height.px': 4 }">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated"
      role="progressbar"
      [attr.aria-valuenow]="
        progressNumber[loadingState || loadingEnum.Selecting]
      "
      aria-valuemin="0"
      aria-valuemax="100"
      [ngStyle]="{
        'width.%': progressNumber[loadingState || loadingEnum.Selecting],
      }"
    ></div>
  </div>
}
@if (state === stateEnum.Error) {
  @if (error) {
    <div class="alert alert-danger small text-break mt-2 p-2" role="alert">
      {{ error }}
    </div>
  }
}
<input
  class="form-control visually-hidden"
  type="file"
  [accept]="accept"
  aria-label="file"
  [formlyAttributes]="field"
  (change)="onChange($event)"
  #fileInput
/>

<div class="input-group">
  @if (isMillisecond) {
    <input
      type="number"
      class="form-control"
      placeholder="Milliseconds"
      [(ngModel)]="timeModel.milliseconds"
      (change)="onChange()"
      [formlyAttributes]="field"
    />
    <span class="input-group-text">ms</span>
  } @else {
    <input
      type="number"
      class="form-control"
      placeholder="Minutes"
      [(ngModel)]="timeModel.minutes"
      (change)="onChange()"
      [formlyAttributes]="field"
    />
    <span class="input-group-text">min</span>
    <input
      type="number"
      class="form-control"
      placeholder="Seconds"
      [(ngModel)]="timeModel.seconds"
      (change)="onChange()"
      [formlyAttributes]="field"
    />
    <span class="input-group-text">sec</span>
  }

  <button
    type="button"
    aria-label="Toggle milliseconds"
    class="btn"
    (click)="isMillisecond = !isMillisecond"
  >
    @if (isMillisecond) {
      <i class="bi bi-clock-history"></i>
    } @else {
      <i class="bi bi-clock"></i>
    }
  </button>
</div>
